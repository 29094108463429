@import "~app/mixins";

$border-color: #dfe1e4;

.panel {
  padding: 15px 25px;
  background: $color-white;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.1);

  .panel {
    box-shadow: none;
    padding: 0 0 0 10px;
  }

  &.blue,
  &.blueOutline {
    border: 1px solid #3476cb;
  }

  &.blue {
    background-color: #e5eaf1;
  }
}

.circularBarDiv{
  display: flex;
  border-top: 1px solid #DFE1E4;
  min-height: 44px;
  align-items: center;
  padding: 7px 0px;
}

/* CSS for Propstream Intelligence Bar & Tab */
.wordScoreSection{
  padding: 0px 24px;
  margin-top: 16px;
  display: flex;
  align-items: flex-start;
  gap: 64px;

  .wordScoreCard{
    margin-bottom: 6px;
    width: 100%;

    .wordScorePoints{
      margin-top: 6px;
      color: #66707D;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;

      .custom_list {
        margin: 8px;       
      }
      
      .custom_list_item {
        position: relative;      
        margin-bottom: 10px;    
        padding-left: 20px;     
      }
      
      .custom_list_item::before {
        content: '\2022';        
        position: absolute;    
        left: 0;           
        top: 0;
        font-size: 20px;       
        color: #66707D;;         
      }
      
    }
    .wordScoreCardTop{
      display: flex;
      align-items: center;
      gap: 16px;

      .wordScoreLabel{
        color: #3C4654;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
      }
    }
  }
}

.dropdownToggleBtn {
  height: 30px;
  min-width: 110px;
  border: 1px solid #128292;
  border-radius: 999px;
  background: #128292;
  padding: 0px 16px;
  transition: all 150ms;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  font-weight: 600;
  display: flex;
  align-items: center;

  &.dropdownSaveSerchBtn {
    min-width: 175px;
  }

  &:focus {
    background: #0a464f;
  }

  &:hover {
    background-color: #0e6673;
  }

  @include text-overflow;
}

.dropdownCaretIcon {
  width: 12px;
  height: 9px;
  position: absolute;
  right: 15px;
  fill: $color-white;
}

.intelligenceDivHr{
  background-color: #BCC1C7;
  width: 1px;
  height: 22px;
  margin: 0;
}

.intelliExpandRow{
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0px 24px;

  .intelliExpandText{
    color: #3C4654;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }
}

.intelliCol{
  display: flex;
  align-items: center;

  .intelligenceInfoHr{
    background-color: #BCC1C7;
    width: 1px;
    height: 39px;
    margin: 0;
  }

  .intelliRow{
    width: 50%;

    .intelliExpandText{
      color: #3C4654;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; 
      padding: 0px 24px;
    }
  }
}

.IntelligenceOuterDiv{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 48px 16px 24px;
}

.IntelligenceInnerDiv12{
  display: flex;
  align-items: center;
  gap: 12px;

  .name{
    color:  #3C4654;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }
}

.IntelligenceInnerDiv{
  display: flex;
  align-items: center;
  gap: 8px;

  .name{
    color:  #3C4654;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }
}

.propConditionSection{
  display: flex;
  align-items: center;
  gap: 39px;
  justify-content: space-between;
  padding: 0px 48px 0px 24px;
}

.propSummary{
  color: #66707D;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; 
  max-width: 71%;
}

.foreclosureSummary{
  color: #66707D;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; 
  padding: 20px 24px;
}

.propConditionRating{
  display: flex;
  align-items: center;
  gap: 12px;

  .propRatingHeader{
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }
}

.propConditionTitle{
  display: flex;
  align-items: center;
  gap: 7px;

  .propConditionHeader{
    color: #3C4654;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }
}

.intelliInfoSection{
  padding: 24px;
}

.inteliiIcon{
  display: inline-block;
  height: 15px;
  padding-right: 5px;
}

.intelliHeader{
  margin: 9px 23px 0px 23px;
  font-weight: 600;
  line-height: 140%;
  color: #3C4654;
  font-family: Inter;
}

.intelliHeaderInfo{
  color:  #66707D;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  margin: 0px 23px 9px 23px;
}

.bar {
  width: 1px; /* Thickness of the line */
  height: 16px; /* Height of the line */
  background-color: #66707D; /* Color of the line */
   margin: 0 4px; /* Space around the line */
   display: inline-block;

}

.additionalClass{
  display: flex;
  align-items: center;
}

.percentLabel{
  color: #66707D;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 14px */
  // margin-left: 4px;

}

.IntelligenceValueCellDiv{
  display: flex;
  gap: 12px;
  align-items: center;
}

.IntelligenceCellIcon{
  width: 30px;
  height: 26px;
}

.IntelligenceCellImg{
  width: 100%;
  height: 100%;
}

.verticalLine {
  position: absolute;
  top: 0;
  height: 100%;
  width: 2px; // Adjust the width of the vertical lines
  background-color: #000; // The color of the vertical lines
}

.scorelabel{
  color: #3C4654;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 16px */
}

.scoreTitle{
  color: #66707D;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

.intelligencePanel{
  padding: 8px 0 8px 0;
  
  .intelligencePanelDiv{
    display: flex;
    // padding: 12px 48px 12px 23px;
    align-items: center;
    gap: 35px;
    min-height: 63px;
    align-self: stretch;

    .intelligenceTitleTop{
      margin: 0;
      color: #3C4654;
      font-family: Inter;
      font-size: 15.8px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 15.8px */
    }

    .intelligenceTitleBottom{
      margin: 0;
      color: #5B68C3;
      font-family: Inter;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
    }

    .intelligenceIconDiv{
      display: flex;
      gap: 3px
    }

    .intelligenceImg{
      margin-top: 5.3px;
      width: 20.417px;
      height: 20.417px;
      flex-shrink: 0;
    }

    .intelligenceDetaials{
      display: flex;
      padding: 12px;
      align-items: center;
      gap: 12px;
      border-radius: 8px;
      background: #EFF2F6;

      .intelligenceInfoHr{
        background-color: #BCC1C7;
        width: 1px;
        height: 39px;
        margin: 0;
      }

      .intelligenceInfo{
        .intelligenceInfoText{
          color: #3C4654;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 19.2px */
          margin-bottom: 0;
        }
        .intelligenceInfoIcon{
          background-color: #128292;
          color: #fff;
          /* tooltip */
          font-family: "Font Awesome 6 Pro";
          font-size: 10px;
          font-style: normal;
          font-weight: 900;
          line-height: 130%;
          display: inline-flex;
          height: 15px;
          width: 15px;
          border-radius: 100px;
          align-items: center;
          justify-content: center;

        }
        .intelligenceInfoIcon:hover{cursor: pointer;}
      }

    }
  }


}


.label {
  color: $color-teal;
  font-size: 12px;
  font-weight: 500;
  line-height: 100%;
}

.hidden {
  visibility: hidden;
}

.items {
  .item {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    font-size: 12px;
    padding: 8px; 
    height: 28px;
    line-height: 100%;
    justify-content: space-between;

    .label {
      width: 130px;

      span {
        margin-left: 5px;
        color: $color-grey;
      }
    }

    .value {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 140px;
      text-align: right;
    }

    &:nth-child(odd) {
      background-color: #f3f5f8;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin-top: -15px;
  padding-bottom: 15px;
  height: 50px;

  .headerTitle {
    // color: $color-teal;
    color: $color-charcoal;
    font-size: 18px;
    line-height: 18px;
    padding-top: 10px;
    font-weight: 600;
    @include text-overflow;
    font-family: $font-inter;
  }

  .buttons {
    margin-right: 25px;
    margin-top: 3px;
    display: flex;
    height: 30px;
    div, a, button {
      font-family: $font-inter;
    }

    button {
      margin: 0 8px;
      font-weight: bold;
      font-size: 14px;
      width: auto;
      border-radius: 50px;
      background-color: #fff;
      color: $color-teal;
    }

    .buttonExport {
      background: none;
      border: none;
      padding: 0;
      margin: 0 15px;

      &:hover {
        opacity: 0.8;
      }
    }

    .iconExport {
      width: 26px;
      height: 24px;
    }

    .iconDownload {
      width: 26px;
      height: 24px;
      cursor: pointer;
      margin-right: 10px;
    }
  }
}

.propertyDetail {
  margin-left: -15px;
  margin-right: 15px;
  width: calc(100% + 30px);
  font-family: $font-inter;

  .rowPanels {
    display: flex;
    justify-content: space-between; 

    .panel { 
      .valueSection .title {
        font-weight: bold;
        font-size: 12px;
        margin-top: 6px;
        margin-bottom: 2px;
        color: #888;
      }

      .valueSection .values .value {
        margin-bottom: 0;
      }
 
    }

    .panel {
      padding: 0px;
      border-radius: 10px;
      background: $color-white;
      box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.1);
      width: 265px;
      height: 337px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &:first-child {
        width: 500px;
        padding: 14px 15px;
      } 

      .propertyInfo {
        display: flex;
        align-items: flex-end;
        color: $color-grey;
        font-family: $font-inter;

        .items {
          .item {
            .label {
              width: 85px;
            }
          }
          .bedBathItem {
            height: 48px;
            .label {
              display: flex;
              margin: 1em;
              font-weight: 600 !important;
              .span {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 4px;
                span {
                  text-align: center;
                  line-height: 100%;
                  &:nth-child(1) {
                    font-size: 10px;
                  }
                  &:nth-child(2) {
                    font-size: 16px;
                    font-weight: 400;
                  }
                }
              }
            }
          }
        }

        .font600 {
          .item {
            .label {
              font-weight: 600 !important;
            }
          }
        }

        .left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 308px;
          width: 200px; 

          .items {
            .item:first-child {
              font-weight: bold;
            }
          }

          .image {
            position: relative;
            height: 140px;
            cursor: pointer;

            .pic {
              height: 100%;
              background-size: cover;
              border-radius: 4px;
            }

            img {
              display: block;
              max-width: 100%;
              height: auto;
              border-radius: 4px;
            }

            .circle {
              right: 5px;
              background: $color-white;
              top: 5px;
              width: 30px;
              position: absolute;
              border-radius: 50%;
              height: 30px;
              line-height: 30px;
              font-size: 14px;
              font-weight: bold;
              text-align: center;
              pointer-events: none;
              color: $color-teal;
            }
          }
        }

        .right.items { 
          padding-left: 8px;
          width: 100%;

          .label {
            font-weight: 500;
          }

          .value {
            max-width: 140px;
          }
        }
      }

      .valueGrid {
        height: 100%; 

        .cardsButton {
          color: #f16722;
          text-align: center;
          font-size: 14px;
          font-weight: 700;
          padding-bottom: 0;
          margin: 0px;
          background: #fff;
          outline: none;
          border: 0;
          width: 100%;
          height: 34px;
          line-height: 100%;
          border-radius: 12px;
        }
      }

      .valueSection {
        font-family: $font-inter;
        .label { 
          padding: 0px 10px; 
          font-weight: 600;
          font-family: $font-inter;
          // line-height: 100%;
          &:nth-child(1) {
            font-weight: 600;
            line-height: 100%;
            font-size: 24px;
            padding: 12px 10px;
          }

        }

        .subLabel {
          color: $color-teal;
          font-size: 18px;
          font-weight: 600; 
          padding: 6px 10px;
          line-height: 100%;
        }

        .values {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          .value {
            width: 48%;
            flex: 50% 1 0;
            background: $color-dirty-white;
            min-height: 52px;

            &:nth-child(3),
            &:nth-child(4) {
              background-color: #ffff;
              border-bottom: solid 1px #dfe1e4;
            }

            &:nth-child(odd) {
              position: relative;

              &:after {
                content: "";
                position: absolute;
                right: 0;
                width: 1px;
                height: 65%;
                background: #dfe1e4;
                top: 50%;
                transform: translateY(-50%);
              }
            }

            .label {
              font-size: 16px;
              color: #3c4654;
              position: relative;
              line-height: 100%;
              padding: 4px 10px 10px 10px;

              .description {
                left: 10px;
                right: 0;
                top: 15px;
                color: $color-grey-light;
                font-size: 10px;
                line-height: 100%;
                margin-top: 2px;
              }
            }

            .name { 
              padding: 10px 10px 0 10px;
              color: #66707D;
              font-size: 12px;
              font-weight: 500;
              white-space: nowrap;
              line-height: 100%;
            }
          }
        }
        .valuesWithLightBg {
          .value {
            background-color: #ffff;
            border-bottom: solid 1px #dfe1e4;
          }
        }

        .graph {
          text-align: center;
          tspan, text {
            font-size: 12px;
          }
          > div {
            padding: 0;
            :global .recharts-wrapper {
              display: flex;
              padding: 4px 8px;
            }
          }
        }

        .panelButtons {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          a,
          button {
            margin: 0;
            width: 160px;
          }

          a {
            margin-top: 6px;
          }

          svg {
            margin-left: 5px;
          }

          > button {
            margin-top: 4px;
          }
        }
      }
    }
  }

  .status {
    color: $color-grey;
    font-size: 13px;
    margin-top: 10px;
    display: flex;

    .statusLabel {
      font-weight: bold;
    }

    div.statusLabel {
      margin-bottom: 6px;
    }

    .statusSide {
      display: flex;
      flex-direction: column;

      &.statusLeft {
        flex: 1;

        .statusTag .statusValue {
          color: #e5541e;
        }

        .statusNote {
          word-break: break-word;
        }
      }

      &.statusRight {
        min-width: 340px;
        margin-left: 14px;

        .member {
          display: flex;

          > div {
            display: flex;
            align-items: center;

            &:first-child {
              flex: 1;
            }

            &:last-child {
              width: 100px;
              margin-left: 10px;
              white-space: nowrap;

              .memberTypeIcon {
                border: 2px solid white;
                width: 28px;
                height: 28px;
                border-radius: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 10px;
              }
            }

            > :first-child {
              margin-right: 8px;
            }
          }
        }
      }

      .panel {
        border-radius: 12px;
        padding: 12px 20px;

        & + .panel {
          margin-top: 12px;
        }

        &:last-child {
          flex: 1;
        }
      }
    }
  }

  .tabs {
    margin-top: 20px;

    .tab {
      position: relative;

      .intelliTab{
        color: #5B68C3 !important;
      }
      
      &:hover {
        color: $color-teal;
      }
    }
    [aria-selected=true] {
      color: $color-teal !important;

      .intelliTab{
        color: #5B68C3 !important;
      }
    }

    .panel {
      margin-top: 8px;
    }

    .heading {
      color: #384048;
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      margin-bottom: 6px;
      display: flex;
      justify-content: space-between;
    }

    .documentTypeImage {
      display: flex;
      align-items: center;

      div {
        height: 22px;

        img {
          margin-left: 8px;
          width: 22px;
          height: 100%;
          cursor: pointer;
        }
      }

      &.loading {
        pointer-events: none;
      }
    }

    .page {
      $offset: 10px;

      margin: 5px 0 8px;
      width: 100%;

      .caption {
        color: $color-blue-dark;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 12px;
      }

      .row {
        display: flex;
        flex-wrap: wrap;
        margin-left: -$offset;
        margin-right: -$offset;
        margin-bottom: 2px;
      }

      .cell {
        width: 20%;
        padding-left: $offset;
        padding-right: $offset;

        &.single {
          flex: 0 1 100%;
        }

        a,
        a:hover {
          color: blue;
          text-decoration: underline;
        }

        .grid {
          margin-top: 4px;
          color: $color-grey;
          font-size: 13px;

          :global {
            .ag-theme-balham {
              .ag-root {
                .ag-header-cell-text {
                  // color: #c5481c;
                }
              }
            }
          }
        }
      }

      .break {
        flex: 0 1 100%;
        height: 1px;
        margin-top: -1px;
      }

      .heading {
        margin-bottom: 0;
        display: flex;
        justify-content: space-between;

        .details {
          display: flex;
          font-weight: normal;
          font-size: 13px;

          .detail {
            margin-left: 10px;
          }
        }

        + .row > .page:first-child > .panel > div > .heading {
          margin-top: 20px;
        }
      }
    }

    .indexCell.cashBuyer {
      span {
        padding: 1px 3px;
        border-radius: 5px;
        color: white;
        background-color: $color-green;
      }
    }

    .trendTable {
      .minus {
        color: #cc0d00;
      }

      .plus {
        color: #87d600;
      }
    }

    .tax .trendTable {
      margin: 30px 0 20px;
      width: 50%;
    }

    .comps {
      .searchItems {
        $offset: 4px;

        display: flex;
        margin: -$offset 0 20px;

        > a,
        > div {
          margin: 0 $offset;
          width: 116px;
        }

        button {
          width: 100%;
        }
      }

      .title {
        color: $color-blue-dark;
        font-size: 16px;
        line-height: 18px;
        padding-bottom: 5px;
      }

      .content {
        display: flex;
        flex-wrap: nowrap;
      }

      .left,
      .right {
        position: relative;
      }

      .left,
      .leftSearch {
        width: 45%;
      }

      .right,
      .rightSearch {
        width: 55%;
      }

      .right {
        padding: 0 0 0 15px;

        .fix {
          // 30px is 2 padding by 15px
          width: calc(100% - 30px);
        }
      }

      .range {
        display: flex;
        align-items: center;
        width: 190px;

        .divider {
          color: $color-grey-1;
          font-size: 12px;
          line-height: 18px;
          padding: 0 10px;
        }
      }

      .flexRow {
        display: flex;
        justify-content: space-between;
      }

      .justifyContent {
        display: flex;
        justify-content: space-between;
      }

      .flexRow,
      .range {
        $width: 80px;

        input[type="text"] {
          width: $width;
          border-color: $color-blue-darkest;
          color: $color-grey-1;

          @include placeholder($color-grey-1);

          &.dateInput {
            width: 105px;
          }
        }

        label {
          white-space: nowrap;
          width: $width;
        }
      }

      .radioGroup {
        width: 230px;
      }

      .mapWrap {
        width: 100%;
        height: 405px;
        border: 1px solid $color-grey;
        position: relative;
      }

      .button {
        font-weight: normal;
      }

      .tableComparableProperties {
        margin-top: 10px;
        overflow-x: hidden;
      }

      .tableComparablePropertiesExtraSpace {
        margin-top: 16px;
      }

      .buttonsExpanded {
        text-align: center;
        padding: 15px 0;
      }

      .buttonExpanded {
        margin: 0 5px;
      }

      .contentWrapper {
        height: calc(100vh - 570px);
        overflow-x: hidden;
        overflow-y: auto;
        margin-top: 1px;
        position: relative;
      }

      .fix {
        width: 100%;
        position: absolute;
      }
    }

    .linkedProperties {
      .summary {
        margin: 20px 0;
        display: flex;
        justify-content: center;

        .summaryItem {
          min-width: 230px;
          padding: 0 10px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .summaryValue {
            font-size: 30px;
            color: #666;
            font-weight: bold;
            margin-bottom: 5px;
          }

          .summaryLabel {
            color: $color-grey;
          }
        }
      }

      :global {
        .ag-theme-balham {
          .ag-root {
            .ag-cell {
              display: flex;
              align-items: center;
              padding-top: 6px;
              line-height: 16px;

              a {
                color: blue;
              }
            }
          }
        }
      }
    }

    .transactions {
      :global {
        .ag-header {
          .ag-header-cell:first-child:after {
            border-style: none;
          }
        }
      }
    }

    :global {
      .ag-theme-balham {
        .ag-root {
          border-style: none;

          .ag-header {
            background-color: #f5f7f7;
          }

          .ag-icon {
            margin-left: -4px;
          }

          .ag-details-row {
            background-color: #e8f6eb;

            * {
              background-color: transparent;
            }
          }

          .ag-row.ag-row-group {
            background-color: #ececec;
            padding-left: 5px;
          }
        }
      }
    }
  }
}
.equityTxt {
  font-size: 12px;
  font-weight: 500;
  color: #66707d;
  padding: 0 5px;
  //margin-top: 5px;
}
.barData {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 3px 0;
  li {
    list-style: none;
    padding: 0 5px;
    color: #3c4654;
    text-align: center;
    font-size: 14px;
    font-weight: 400;

    &:nth-of-type(1) {
      font-size: 16px;
      font-weight: 600;
    }
    &:nth-of-type(2) {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        width: 1px;
        height: 15px;
        background: #99a0aa;
        left: 0;
      }
      &:after {
        content: "";
        position: absolute;
        width: 1px;
        height: 15px;
        background: #99a0aa;
        right: 0;
      }
    }
  }
}

.singleCell {
  &:after {
    background-color: $color-white !important;
  }
}

.flexBasis100 {
  flex-basis: 100%;
}

.dropDownToggle {
  button {
    min-width: 115px;
    background-color: $color-teal !important;
  }
  button > div:nth-of-type(1) {
    // justify-content: flex-start !important;
    background-color: $color-teal;
    color: #fff;
    justify-content: space-between;
    padding: 2px;
  }

  .toolsButton {
    display: flex;
    align-items: center;
    .buttonLabel {
      color: $color-white;
      font-size: 8px;
      font-weight: 700;
      background-color: $color-orange;
      border-radius: 999px;
      padding: 2px 8px;
      margin-left: 5px;
    }

    .toolsIcon{
      padding-right: 5px;
    }

    .iconCaretDown {
      fill: #fff;
      // position: absolute;
      right: 15px;
      top: 8px;
    }
  }

  .dropdownList {
    background-color: $color-white;
    border: solid 1px $color-teal;
    border-radius: 15px;
    margin-top: 10px;
    position: relative;
    z-index: 999;
    margin: 5px;

    .dropdownItem {
      font-size: 12px;
      font-weight: 600;
      color: $color-grey;
      padding: 5px 5px;
      display: flex;
      align-items: center;
      text-decoration: none !important;

      &:hover {
        background-color: $color-grey-lighter;
      }
    }

    .topBorderItem{
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;  
    }
    .botBorderItem{
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }

  .aduLabel {
    color: $color-white;
    font-size: 8px;
    font-weight: 700;
    background-color: $color-teal;
    border-radius: 999px;
    padding: 3px 8px;
    margin-left: 5px;
    line-height: 10px;
  }
}
